import { HttpService } from './services/http.service';
import { AppRoutes } from './app.routing';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {formatDate } from '@angular/common';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AlertService } from './services/alert/alert.service';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { MyInterceptor } from './app.interceptor';
import { NgxSpinnerModule } from 'ngx-spinner';
import { LoaderService } from './services/loader/loader.service';
import { PricePipe } from './pipes/price.pipe';
import { ErrorInterceptor } from './services/_helpers/error.interceptor';


@NgModule({
   declarations: [
      AppComponent,
      
   ],
   imports: [
      BrowserModule,
      BrowserAnimationsModule,
      AppRoutes,
      HttpClientModule,
      NgxSpinnerModule,
      ToastrModule.forRoot()
   ],

   
   providers: [
      HttpService,
      AlertService,
      LoaderService,
      { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
      
   ],
   bootstrap: [
      AppComponent
   ]
})
export class AppModule { }
