import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse
} from '@angular/common/http';
import { Router } from '@angular/router';
import { AlertService } from './alert/alert.service';
import { observable, Observable, of } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, map, mergeMap } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class HttpService {
  // public baseUrl = 'http://192.168.10.171:8000/';
  // public baseUrl = 'http://192.168.1.110:8000/';
  // public baseUrl = 'http://192.168.10.29:8000/';
  // public baseUrl = 'http://doodlebluelive.com:2060/'; // stagging
  // public baseUrl = 'http://localhost:8000/';

  //  prod BaseAPI
  public baseUrl = 'https://api.ivarjewelry.com/'; // live

 

  public token: any;
  public headers: any;
  public headers2: any;
  constructor(
    private http: HttpClient,
    private router: Router,
    private alert: AlertService
  ) {
    this.token = localStorage.getItem('token');
    this.headers = {
      headers: new HttpHeaders({
        Authorization: this.token,
        'Content-Type': 'application/json'
      })
    };

    this.headers2 = {
      headers: new HttpHeaders({
        Authorization: this.token,
        // 'Content-Type': 'multipart/form-data'
      })
    };
  }

  loginToIvar = data => {
    return this.http.post(this.baseUrl + 'user/login', data);
  };

  handleError = (error: HttpErrorResponse) => {
    if (error.error instanceof ErrorEvent) {
      console.log('Client side');
    } else {
      console.log('server side error');
    }
  }

  // logout() {
  //   // remove user from local storage to log user out
  //   localStorage.removeItem('token');
  // }

  Logout = () => {
    return this.http.post(this.baseUrl + 'user/logout', {}, this.headers);
  }

  logout = () => {
    localStorage.clear();
    this.router.navigate(['login']);
  }

  // dashboard detail
  getAllCategoryById = () => {

    return this.http.get(this.baseUrl + 'product/getallcategory');

  }

  getDashboardDetail = () => {
    return this.http.get(this.baseUrl + `user/dashboard`);
  }

  getBarChartDetail = (begindate, enddate, storeName, category) => {
    return this.http.get(
      this.baseUrl + `manager/barchart?fromDate=${begindate}&toDate=${enddate}&storeName=${storeName}&category=${category}`
    );
  }

  getPieChartDetail = () => {
    return this.http.get(this.baseUrl + `manager/piechart`);
  }

  getSalesDetail = (begindate, enddate, currency, storeName, category) => {
    console.log(this)
    return this.http.get(this.baseUrl + `manager/salesanalytics?fromDate=${begindate}&toDate=${enddate}&currency=${currency}&storeName=${storeName}&category=${category}`);
  }

  getDashboardSalesDetail = (begindate, enddate, currency, storeName, category) => {
    console.log(this)
    return this.http.get(this.baseUrl + `manager/dashboard?fromDate=${begindate}&toDate=${enddate}&currency=${currency}&storeName=${storeName}&category=${category}`);
  }

  //Currency Convertor Methods
  // getCurrencyData = (data) => {
  //   console.log(data)
    
  //   // return this.http.get(this.currencyUrl + `api/v7/convert/api/latest?access_key=9f1199c5d16e1e5d22933e5f9b848656&from=USD&to=INR&amount=25`, this.headers);
  // }
  getCurrencyData(payload): Observable<any> {
    return this.http
      .post(`${this.baseUrl}costing/currencyConvert`, payload)
      .pipe(

        map((res) => res as any),
       
        );
  }



  // permisson service methods

  getAllStore = params => {
    return this.http.get(this.baseUrl + `user/getallstore?pageNo=${params.pageNo}&value=${params.value}`);
  }

  addNewStore = formdata => {
    console.log(formdata);
    return this.http.post(
      this.baseUrl + 'user/addstore',
      formdata
    );
  }

  pushToInventory = costingId => {
    return this.http.post(
      this.baseUrl + `costing/pushToInventory/${costingId}`, {}
    );
  }

  editStore = storeId => {
    return this.http.get(
      this.baseUrl + `user/editstore/${storeId}`
    );
  }

  getCurrentDate = () => {
    return this.http.get(
      this.baseUrl + `product/getCurrentDate`
    );
  }

  

  updateStore = (storeId, formdata) => {
    return this.http.patch(
      this.baseUrl + `user/updatestore/${storeId}`,
      formdata
    );
  };

  deleteStore = storeId => {
    return this.http.delete(
      this.baseUrl + `user/deletestore/${storeId}`,
    );
  }

  // product CRUD methods

  getProducts = params => {
    return this.http.get(
      this.baseUrl +
      `product/getallproduct?pageNo=${params.pageNo}&value=${params.value}`,
    );
  }

  getCategoryProduct = params => {
    // tslint:disable-next-line:max-line-length
    return this.http.get(
      this.baseUrl +
      `product/getcategory?value=${params.value}&category=${params.category
      }&pageNo=${params.pageNo}`
    );
  }

  getListProduct = params => {
    // tslint:disable-next-line:max-line-length
    return this.http.get(
      this.baseUrl +
      `product/productlist?value=${params.value}&category=${params.category
      }&pageNo=${params.pageNo}&name=${params.name}&location=${params.location
      }`,
    );
  }

  getListProductPopUp = params => {
    // tslint:disable-next-line:max-line-length
    return this.http.get(
      this.baseUrl + `product/print?value=&category=${params.category}&name=${params.name}&location=${params.location}`,
    );
  }

  getMakerDropdown = () => {

    return this.http.get(this.baseUrl + 'user/makers/dropdown');

  }

  getClientDropdown = () => {

    return this.http.get(this.baseUrl + 'user/customerlist/dropdown');

  }

  getProductDropdown = category => {

    return this.http.get(this.baseUrl + `user/productName/dropdown?searchItem=${category}`);

  }

  // getCategoryDropdown = () => {

  //   return this.http.get(this.baseUrl + 'user/productName/dropdown');

  // }


  getVendorDropdown = () => {

    return this.http.get(this.baseUrl + 'user/vendor/dropdown');

  }

  getLocationDropdown = () => {

    return this.http.get(this.baseUrl + 'user/location/dropdown?location=');

  }

  getMakerLocationDropdown = () => {

    return this.http.get(this.baseUrl + 'user/makersLocation/dropdown');

  }

  addProduct = formdata => {
    console.log(formdata);
    return this.http.post(
      this.baseUrl + 'product/addproduct',
      formdata
    );
  }

  addOrder = formdata => {
    console.log(formdata, "This COnsole");
    return this.http.post(
      this.baseUrl + 'orders/createOrder',
      formdata
    );
  }
  addPurchase = formdata => {
    console.log(formdata, "This COnsole");
    return this.http.post(
      this.baseUrl + 'costing/createpurchase',
      formdata
    );
  }
  updatePurchase = (orderId, data) => {
    return this.http.patch(
      this.baseUrl + `costing/updatepurchase/${orderId}`,
      data
    );
  }
  addGem = formdata => {
    console.log(formdata, "This COnsole");
    return this.http.post(
      this.baseUrl + 'costing/createGem',
      formdata
    );
  }
  updatePurchaseGem = (orderId, data) => {
    return this.http.patch(
      this.baseUrl + `costing/updateGem/${orderId}`,
      data
    );
  }
  getAllPurchase = (params) => {
    // console.log(formdata,"This COnsole");
    return this.http.get(
      this.baseUrl + `costing/getallpurchase?pageno=${params.pageno}&value=${params.value}`,

    );
  }
  getAllGems = (params) => {
    // console.log(formdata,"This COnsole");
    return this.http.get(
      this.baseUrl + `costing/getAllGem?pageno=${params.pageno}&value=${params.value}`,
    );
  }

  getOrders = obj => {
    return this.http.get(
      this.baseUrl +
      `orders/getOrder?pageNo=${obj.pageNo}&value=${obj.value}`
    );
    // pageNo=${obj.pageNo}&value=${obj.value}
  }
  editOrder = params => {
    return this.http.get(
      this.baseUrl + `orders/editorder/${params.orderId}?pageNo=${params.pageNo}`,
    );

  }

  updateOrder = (orderId, data) => {
    return this.http.patch(
      this.baseUrl + `orders/updateOrder/${orderId}`,
      data
    );
  }

  deleteOrder = orderId => {
    console.log(orderId, 'This is orderIID')
    return this.http.delete(
      this.baseUrl + `orders/deleteOrder/${orderId}`
    );
  }

  deletePurchase = orderId => {
    console.log(orderId, 'This is orderIID')
    return this.http.delete(
      this.baseUrl + `costing/deletepurchase/${orderId}`
    );
  }
  deleteGems = orderId => {
    console.log(orderId, 'This is orderIID')
    return this.http.delete(
      this.baseUrl + `costing/deletepurchase/${orderId}`
    );
  }

  addCost = formdata => {
    console.log(formdata, "This COnsole");
    return this.http.post(
      this.baseUrl + 'costing/createCosting',
      formdata
    );
  }
  editCost = params => {
    return this.http.get(

      this.baseUrl + `costing/editCosting/${params.costId}?pageNo=${params.pageNo}`,
    );

  }
  updateCosting = (costId, data) => {
    return this.http.patch(
      this.baseUrl + `costing/updatepurchase/${costId}`,
      data
    );
  }


  getCosting = obj => {
    return this.http.get(
      this.baseUrl +
      `costing/getCosting?pageNo=${obj.pageNo}&value=${obj.value}`
    );
    // pageNo=${obj.pageNo}&value=${obj.value}
  }
  getPurchase = obj => {
    return this.http.get(
      this.baseUrl +
      `costing/getallpurchase?pageno=${obj.pageNo}&value=${obj.value}`
    );
    // pageNo=${obj.pageNo}&value=${obj.value}
  }
  editPurchase = params => {
    return this.http.get(

      this.baseUrl + `costing/getpurchase/${params.costId}?pageNo=${params.pageNo}`,
    );

  }
  editPurchaseGem = params => {
    return this.http.get(

      this.baseUrl + `costing/getGemById/${params.costId}?pageNo=${params.pageNo}`,
    );

  }


  editProduct = productId => {
    return this.http.get(
      this.baseUrl + `product/editproduct/${productId}`
    );
  }

  updateProduct = (formdata, productId) => {
    return this.http.patch(
      this.baseUrl + `product/updateproduct/${productId}`,
      formdata
    );
  }

  updateLocation = (productId, desc) => {
    return this.http.put(
      this.baseUrl +
      `product/updatelocation?productId=${productId}&stoneDescription=${desc}`,
      {}
    );
  }

  deleteProduct = productId => {
    console.log(this.headers2);
    return this.http.delete(
      this.baseUrl + `product/deleteproduct/${productId}`
    );
  }

  // Customers CRUD

  getCustomers = obj => {
    return this.http.get(
      this.baseUrl +
      `customer/getallcustomer?pageNo=${obj.pageNo}&value=${obj.value}`
    );
  }

  // sold product detail

  getsoldProductDetail = obj => {
    return this.http.get(this.baseUrl + `soldProducts/getProducts?pageNo=${obj.pageNo}&value=${obj.value}`);
  }

  addCustomer = data => {
    return this.http.post(
      this.baseUrl + `customer/addcustomer`,
      data,
    );
  }

  editCustomer = params => {
    return this.http.get(
      this.baseUrl +
      `customer/editcustomer/${params.customerId}?pageNo=${params.pageNo}`,
    );
  }

  updateCustomer = (customerId, data) => {
    return this.http.patch(
      this.baseUrl + `customer/updatecustomer/${customerId}`,
      data
    );
  }

  deleteCustomer = customerId => {
    console.log(this.headers2);
    return this.http.delete(
      this.baseUrl + `customer/deletecustomer/${customerId}`
    );
  }

  // inventory CRUD

  getAllStoreById = () => {
    return this.http.get(this.baseUrl + `manager/getstore`);
  }

  getAllInventories = params => {
    // tslint:disable-next-line:max-line-length
    return this.http.get(
      this.baseUrl +
      `manager/productstock?pageNo=${params.pageNo}&storeId=${params.storeId
      }&value=${params.value}`
    );
  }

  editStock = params => {
    return this.http.get(
      this.baseUrl +
      `manager/editstock/${params.storeId}?name=${params.name}&pageNo=${params.pageNo
      }&value=${params.value}`
    );
  }

  updateStock = params => {
    return this.http.put(
      this.baseUrl +
      `manager/updatestock/?storeId=${params.storeId}&productId=${params.productId
      }`,
      params.data
    );
  }

  // invoice CRUD

  getAllCustomerNameAndId = value => {
    return this.http.get(
      this.baseUrl + `invoice/customerlist/?value=${value}`
    );
  }

  getAllCustomers = value => {
    return this.http.get(
      this.baseUrl + `invoice/customerlist/?value=${value}`

    );
  }

  getInvoiceProducts = storeId => {
    return this.http.get(
      this.baseUrl + `invoice/productlist/?storeId=${storeId}`
    );
  }

  autoCost = data =>{
   return this.http.post(this.baseUrl + `costing/ordercost`, data, this.headers);
  }

  totalCost = data =>{
    return this.http.post(this.baseUrl + `costing/totalCost`, data, this.headers);
   }
  

  autoStoneCalc = data =>{
  return this.http.post(this.baseUrl + `costing/stoneCost`, data, this.headers);  
  }

  getbysno = serialNumber => {
    return this.http.get(this.baseUrl + `costing/getbysno?serialnumber=${serialNumber}`).pipe(map((res) => res as any ))
  }
// product
  getbyproductsrno(srialnoProdctId){
  return this.http.get(this.baseUrl + `product/getProductbySno?serialNumber=${srialnoProdctId}`).pipe(map((res) => res as any ))
  // return this.http.get('product/getProductbySno?serialNumber=ABCC1112233').pipe(map((res) => res as any ))
  }




  addNewInvoice = data =>
    this.http.post(this.baseUrl + `invoice/addinvoice`, data, this.headers);

  getInvoiceList = obj => {
    // tslint:disable-next-line:max-line-length
    return this.http.get(
      this.baseUrl +
      `invoice/getinvoice?pageNo=${obj.pageNo}&storeId=${obj.storeId}&value=${obj.searchValue
      }&searchType=${obj.type}`
    );
  }

  getInvoiceOfCustomer = invoiceId => {
    return this.http.get(
      this.baseUrl + `invoice/perinvoice/${invoiceId}`
    );
  }

  getSerialNoList = (productName, location) => {
    return this.http.get(
      this.baseUrl +
      `invoice/listserialno?name=${productName}&location=${location}`
    );
  }

  deleteInvoice = invoiceId => {
    return this.http.delete(
      this.baseUrl + `invoice/deleteinvoice/${invoiceId}?type=replace`
    );
  }

  // global search for all customers, invoice, products in headers
  globalSearch = value => {
    return this.http.get(
      this.baseUrl + `invoice/globalsearch?value=${value}`
    );
  }

  // import and download excel

  uploadCSVFile = formdata => {
    console.log(formdata);
    return this.http.post(
      this.baseUrl + `product/exltojson`,
      formdata
    );
  }

  downSampleFile = () => {
    return this.http.get(this.baseUrl + `product/proudctexcel`);
  }
  downSampleFileOrders = () => {
    return this.http.get(this.baseUrl + `orders/downloadorderexcel`);
  }
  downSampleFileCosting = () => {
    return this.http.get(this.baseUrl + `costing/downloadCosting`);
  }


  uploadOrderCSVFile = formdata => {
    // console.log(formdata);
    return this.http.post(
      this.baseUrl + `orders/uploadexcel`,
      formdata
    );
  }
  uploadCostingCSVfile = formdata => {
    // console.log(formdata);
    return this.http.post(
      this.baseUrl + `costing/excelupload`,
      formdata
    );
  }

  // sales

  getSalesOfProduct = (pageNumber, productId) => {
    return this.http.get(
      this.baseUrl + `manager/sale?productId=${productId}&pageNo=${pageNumber}`

    );
  }

  // get countries, states, and cities for customers

  getCountries = () => {
    return this.http.get(this.baseUrl + `customer/country`);
  }

  getStatesById = id => {
    return this.http.get(this.baseUrl + `customer/state/${id}`);
  }

  getCityByStateId = id => {
    return this.http.get(this.baseUrl + `customer/city/${id}`);
  }

  // status

  getStatusList = params => {
    return this.http.get(
      this.baseUrl +
      `product/listproduct?pageNo=${params.pageNo}&value=${params.value}`

    );
  }

  updateLocationStatus = params => {
    console.log(params);
    return this.http.put(
      this.baseUrl +
      `product/updatelocation?productId=${params.productId}&currentLocation=${params.currentLocation
      }&status=${params.status}`,
      {}

    );
  }

  changeAdminPassword = (data) => {
    return this.http.post(
      this.baseUrl + `user/changepassword`, data, this.headers
    );
  }
}

