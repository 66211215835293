import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { HttpService } from '../http.service';
import { Router } from '@angular/router';
import { AlertService } from '../alert/alert.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private _httpService: HttpService,
    private router: Router,
    private injector: Injector,
    private alert: AlertService,
  ) {}

  // intercept(
  //   req: HttpRequest<any>,
  //   next: HttpHandler
  // ): Observable<HttpEvent<any>> {
  //    const token = localStorage.getItem('token');
  //    const headers = {
  //     Authorization: token,
  //   };
  //   const request = req.clone({ setHeaders: headers });

  //    return next.handle(request).pipe(
  //     catchError((err:any) => {
  //       // Client side ...
  //       if (err instanceof HttpErrorResponse) {
  //         console.log('client');
  //         // if(err.status == 500) {
  //         //   console.log('401', err);
  //         //   this.router.navigate(['login']);
  //         // }
  //       } else {
  //         // Server side ...
  //         console.log(err);
  //         this.injector.get(Router).navigate(['login']);
  //         localStorage.clear();
  //         console.log('ser');
  //       }

  //       return throwError(err);
  //     })
  //   );
  // }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('token');
    const headers = {
          Authorization: token,
    };
    const request = req.clone({ setHeaders: headers });
    return next.handle(request).pipe(catchError(err => {
        if (err.status === 401) {
            // auto logout if 401 response returned from api
            this._httpService.Logout();
            localStorage.clear();
            this.router.navigate(['login']);
            location.reload(true);
        }

        const error = err.error.message || err.statusText;
        return throwError(err);
    }));
}

}
